const regexYouTube = /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

const regexVimeo = /(?:http:|https:|)\/\/(?:player.|www.)?vimeo\.com\/(?:video\/|embed\/|watch\?\S*v=|v\/)?(\d*)/;

class VideoProvider {
  static getYouTubeId(url) {
    const reMatches = url.match(regexYouTube);
    if (!reMatches || reMatches[1] === '') return false;
    return reMatches[1];
  }

  static getVimeoId(url) {
    const reMatches = url.match(regexVimeo);
    if (!reMatches || reMatches[1] === '') return false;
    return reMatches[1];
  }

  static validUrls() {
    return [
      'https://www.youtube.com/watch?v=BB1B5MyJiiY',
      'https://www.youtube.com/embed/BB1B5MyJiiY',
      'https://youtu.be/BB1B5MyJiiY',
      'https://vimeo.com/98741946',
    ];
  }
}

export default VideoProvider;
