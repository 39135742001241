<template>
  <blockquote>
    <strong>Examples:</strong>
    <div
      v-for="(url, urlIndex) in videoUrlSamples"
      :key="`key-${urlIndex}`"
      class="text-break text-muted"
    >{{ url }}</div>
  </blockquote>
</template>

<script>
import VideoProvider from '@/core/videoProvider';

export default {
  name: 'VideoUrlSample',
  data() {
    return {
      videoUrlSamples: VideoProvider.validUrls(),
    };
  },
};
</script>
